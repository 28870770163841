import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"latin\",\"cyrillic\"],\"adjustFontFallback\":false,\"variable\":\"--font-main\",\"display\":\"swap\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager", webpackExports: ["CookeiConsent"] */ "/app/src/blocks/CookeiConsent/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/blocks/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/blocks/Header/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
