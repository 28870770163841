// @ts-nocheck
"use client";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Image from "next/image";
import NextLink from "next/link";

import styles from "./styles.module.css";
import Button from "@/components/Button";
import IconButton from "@/components/IconButton";
import {
  BurgerMenuIcon,
  CloseIcon,
  DownArrowIcon,
  DropDownIcon,
  LeftArrowIcon,
  RightArrowIcon,
  UpRightArrowIcon,
  UserIcon,
} from "@/components/Icon";
import Link from "@/components/Link";
import { useMediaQuery, useOnClickOutside } from "usehooks-ts";
import {
  Accordion,
  AccordionItem,
  AccordionContent,
  AccordionTrigger,
} from "@/components/Accordion";
import { useScrollBlock } from "@/hooks/useScrollBlock";
import { usePathname } from "next/navigation";
import { ContactDialog } from "../ContactDialog";
import { useModal } from "@/hooks/useModal";

const Header: React.FC<React.HTMLProps<HTMLElement>> = ({
  className,
  ...rest
}) => {
  const pathname = usePathname();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [menuState, setMenuState] = useState({}); //useState({ services: { open: true } });
  const [subMenuState, setSubMenuState] = useState({}); //useState({ item1: { open: true } });
  const navRef = useRef(null);
  const isDesktopQuery = useMediaQuery("(min-width: 1440px)");
  const isMobileQuery = useMediaQuery("(max-width: 767px)");
  const [blockScroll, allowScroll] = useScrollBlock();
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery]);
  useEffect(() => {
    setIsDesktop(isDesktopQuery);
  }, [isDesktopQuery]);

  const logoAsLink = useMemo(() => pathname.split("/").length > 2, [pathname]);

  const handleCloseMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(false);
    allowScroll();
  }, [allowScroll]);

  const timerId = useRef(undefined);
  const menuOpenTarget = useRef(undefined);

  const handleOpenMenu = useCallback(
    (evt) => {
      if (
        (isDesktop && evt.type == "mouseover") ||
        (!isDesktop && evt.type != "mouseover")
      ) {
        if (!isMobileMenuOpen && Object.keys(menuState).length <= 0) {
          menuOpenTarget.current = evt.target
          evt.target.onmouseleave = (event) => {
            if (timerId.current) return;
            timerId.current = setTimeout(() => {
              setMenuState({});
              setSubMenuState({});
              if (!isMobile) {
                handleCloseMobileMenu();
              }
              timerId.current = undefined;
              evt.target.onmouseleave = () => {};
            }, 500);
          };
          evt.target.onmouseenter = (event) => {
            clearTimeout(timerId.current);
            evt.target.onmouseleave = (event) => {
              timerId.current = setTimeout(() => {
                setMenuState({});
                setSubMenuState({});
                if (!isMobile) {
                  handleCloseMobileMenu();
                }
                timerId.current = undefined;
              }, 500);
              evt.target.onmouseleave = () => {};
            };
          };
          navRef.current = evt.target.nextSibling.nextSibling;
          navRef.current.onmouseenter = (event) => {
            clearTimeout(timerId.current);
            evt.target.onmouseleave = () => {};
            navRef.current.onmouseleave = (event) => {
              timerId.current = setTimeout(() => {
                setMenuState({});
                setSubMenuState({});
                if (!isMobile) {
                  handleCloseMobileMenu();
                }
                timerId.current = undefined;
              }, 500);
              navRef.current.onmouseleave = () => {};
            };
          };
        }
        const key = evt.target.getAttribute("data-key");

        setMenuState({
          [key]: {
            open: true,
          },
        });
      }
    },
    [isDesktop, isMobileMenuOpen, menuState, handleCloseMobileMenu, isMobile]
  );

  const handleOpenMobileMenu = useCallback(
    (evt) => {
      setIsMobileMenuOpen((prev) => {
        if (isDesktop) {
          return false;
        }

        if (prev) {
          allowScroll();
        } else {
          blockScroll();
          navRef.current = document.getElementById("nav");
        }

        return !prev;
      });
    },
    [allowScroll, blockScroll, isDesktop]
  );

  const handleOpenSubMenu = useCallback(
    (evt, key: string) => {
      if (
        (isDesktop && evt.type == "mouseover") ||
        (!isDesktop && evt.type != "mouseover")
      ) {
        if (!key && evt.target) {
          key = evt.target.getAttribute("data-key");
        }
        setSubMenuState({
          [key]: {
            open: true,
          },
        });
      }
    },
    [isDesktop]
  );

  useEffect(() => {
    handleCloseMobileMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useOnClickOutside(navRef, (event) => {
    if(event.target === menuOpenTarget.current) return
    // if (isDesktop) {
    clearTimeout(timerId.current);
    timerId.current = undefined;
    setMenuState({});
    setSubMenuState({});
    if (!isMobile) {
      handleCloseMobileMenu();
    }
    // }
  });

  const handleCloseMenu = useCallback(() => {
    clearTimeout(timerId.current);
    timerId.current = undefined;
    setMenuState({});
    setSubMenuState({});
    if (!isMobile) {
      handleCloseMobileMenu();
    }
  }, [isMobile, handleCloseMobileMenu]);

  const { visible, show, close } = useModal({ defaultVisible: false });

  return (
    <>
      <ContactDialog open={visible} onClose={close} />
      <header className={`${styles.header} ${className || ""}`} {...rest}>
        <NextLink
          href="/"
          className={styles.logo__link}
          data-active={logoAsLink}
        >
          <Image
            src="/logo.svg"
            alt="TrueCode Logo"
            width={157}
            height={42}
            className={styles.logo}
            priority
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
        </NextLink>

        <nav
          className={styles.nav}
          data-open={isMobileMenuOpen}
          data-open-sub={Object.keys(menuState).length > 0}
          id="nav"
        >
          <ul className={styles.nav__list}>
            <li
              className={`${styles.nav__header} ${styles.nav__header__mobile}`}
            >
              <Link href="tel:73952261260" className={styles.mobile__phone}>
                +7 (3952) 26-12-60
              </Link>
              <Button
                className={styles.mobile__button}
                variant="outline"
                size="sm"
                RightElement={UpRightArrowIcon}
                onClick={show}
              >
                Обсудить проект
              </Button>
            </li>
            <li
              className={styles.nav__item}
              data-open={menuState["services"]?.open}
              data-highlighted={menuState["services"]?.open}
            >
              <Link
                asChild
                onMouseOver={handleOpenMenu}
                onClick={handleOpenMenu}
                data-key="services"
                className={`${styles["nav__trigger"]} ${styles["desktop"]}`}
              >
                <NextLink href="/services">
                  Услуги{" "}
                  <DropDownIcon
                    className={`${styles["nav__trigger-icon"]} ${styles["nav__trigger-icon-desktop"]}`}
                    onMouseOver={(e) => e.stopPropagation()}
                  />
                  <RightArrowIcon
                    onMouseOver={(e) => e.stopPropagation()}
                    className={`${styles["dropdown-menu__trigger-icon"]} ${styles["dropdown-menu__trigger-icon-mobile"]}`}
                  />
                </NextLink>
              </Link>
              {/* TODO: Refactor that component, separate mobile and desktop completely */}
              <Link
                href="#"
                onMouseOver={handleOpenMenu}
                onClick={handleOpenMenu}
                data-key="services"
                className={`${styles["nav__trigger"]} ${styles["non-desktop"]}`}
              >
                Услуги{" "}
                <DropDownIcon
                  className={`${styles["nav__trigger-icon"]} ${styles["nav__trigger-icon-desktop"]}`}
                  onMouseOver={(e) => e.stopPropagation()}
                />
                <RightArrowIcon
                  onMouseOver={(e) => e.stopPropagation()}
                  className={`${styles["dropdown-menu__trigger-icon"]} ${styles["dropdown-menu__trigger-icon-mobile"]}`}
                />
              </Link>
              <ul className={styles["nav__list-content"]}>
                <li className={styles["dropdown-menu__back"]}>
                  <Link
                    onClick={() => {
                      setMenuState({});
                      setSubMenuState({});
                    }}
                    href="#"
                    className={styles["dropdown-menu__back-link"]}
                  >
                    <LeftArrowIcon
                      className={`${styles["dropdown-menu__back-icon"]}`}
                    />
                    Назад
                  </Link>
                </li>
                <li
                  className={`${styles["dropdown-menu__item"]} ${styles["dropdown-menu__item__desktop"]}`}
                  data-open={subMenuState["item1"]?.open}
                  data-highlighted={subMenuState["item1"]?.open}
                  data-key="item1"
                  onMouseOver={handleOpenSubMenu}
                  onClick={handleOpenSubMenu}
                >
                  <>
                    <Link
                      data-key="item1"
                      className={`${styles["dropdown-menu__trigger"]}`}
                      asChild
                    >
                      <NextLink href="/services/development">
                        Разработка
                        {/* <RightArrowIcon
                          onMouseOver={(e) => e.stopPropagation()}
                          className={`${styles["dropdown-menu__trigger-icon"]}`}
                        /> */}
                      </NextLink>
                    </Link>
                    {/* <ul
                      className={styles["nav__list-sub-content"]}
                      onMouseOver={(e) => e.stopPropagation()}
                    >
                      <li className={styles["dropdown-menu__item"]}>
                        <Link href="#">Корпоративные сайты</Link>
                      </li>
                      <li className={styles["dropdown-menu__item"]}>
                        <Link href="#">Личные кабинеты</Link>
                      </li>
                      <li className={styles["dropdown-menu__item"]}>
                        <Link href="#">eCommerce и маркетплейсы</Link>
                      </li>
                      <li className={styles["dropdown-menu__item"]}>
                        <Link href="#">
                          Решения для государства и корпораций
                        </Link>
                      </li>
                      <li className={styles["dropdown-menu__item"]}>
                        <Link href="#">Web-Приложения</Link>
                      </li>
                    </ul> */}
                  </>
                </li>
                <li
                  className={`${styles["dropdown-menu__item"]} ${styles["dropdown-menu__item__desktop"]}`}
                  data-open={subMenuState["item2"]?.open}
                  data-highlighted={subMenuState["item2"]?.open}
                  onMouseOver={handleOpenSubMenu}
                  onClick={handleOpenSubMenu}
                  data-key="item2"
                >
                  <Link
                    data-key="item2"
                    className={`${styles["dropdown-menu__trigger"]}`}
                    asChild
                  >
                    <NextLink href="/services/ux-ui">
                      UX/UI
                      {/* <RightArrowIcon
                      onMouseOver={(e) => e.stopPropagation()}
                      className={`${styles["dropdown-menu__trigger-icon"]}`}
                    /> */}
                    </NextLink>
                  </Link>
                  {/* <ul
                    className={styles["nav__list-sub-content"]}
                    onMouseOver={(e) => e.stopPropagation()}
                  >
                    <li className={styles["dropdown-menu__item"]}>
                      <Link href="#">Корпоративные сайты</Link>
                    </li>
                    <li className={styles["dropdown-menu__item"]}>
                      <Link href="#">Личные кабинеты</Link>
                    </li>
                    <li className={styles["dropdown-menu__item"]}>
                      <Link href="#">Web-Приложения</Link>
                    </li>
                  </ul> */}
                </li>
                <li
                  className={`${styles["dropdown-menu__item"]} ${styles["dropdown-menu__item__desktop"]}`}
                  onMouseOver={handleOpenSubMenu}
                  onClick={handleOpenSubMenu}
                >
                  <Link asChild>
                    <NextLink href="/services/integrations">
                      Интеграции
                    </NextLink>
                  </Link>
                </li>
                <li
                  className={`${styles["dropdown-menu__item"]} ${styles["dropdown-menu__item__desktop"]}`}
                  onMouseOver={handleOpenSubMenu}
                  onClick={handleOpenSubMenu}
                >
                  <Link asChild>
                    <NextLink href="/services/promotion">Продвижение</NextLink>
                  </Link>
                </li>
                {/* <li
                  className={`${styles["dropdown-menu__item"]} ${styles["dropdown-menu__item__desktop"]}`}
                  onMouseOver={handleOpenSubMenu}
                  onClick={handleOpenSubMenu}
                >
                  <Link href="#">Фирменный стиль</Link>
                </li>
                <li
                  className={`${styles["dropdown-menu__item"]} ${styles["dropdown-menu__item__desktop"]}`}
                  onMouseOver={handleOpenSubMenu}
                  onClick={handleOpenSubMenu}
                >
                  <Link href="#">Аутстаффинг</Link>
                </li>
                <li
                  className={`${styles["dropdown-menu__item"]} ${styles["dropdown-menu__item__desktop"]}`}
                  onMouseOver={handleOpenSubMenu}
                  onClick={handleOpenSubMenu}
                >
                  <Link href="#">Тех. сопровождение</Link>
                </li>
                <li
                  className={`${styles["dropdown-menu__item"]} ${styles["dropdown-menu__item__desktop"]}`}
                  onMouseOver={handleOpenSubMenu}
                  onClick={handleOpenSubMenu}
                >
                  <Link href="#">Цифровизация бизнеса</Link>
                </li> */}
                <Accordion
                  type="single"
                  className={styles["accordion-container"]}
                  onValueChange={(key) => handleOpenSubMenu({}, key)}
                  collapsible
                  asChild
                >
                  <li>
                    <AccordionItem
                      value={"item1"}
                      className={styles["accordion-item"]}
                      // data-active={currentStep === label}
                      key={"item1"}
                    >
                      <AccordionTrigger
                        className={styles["accordion-trigger"]}
                        asChild
                      >
                        <Link asChild>
                          <NextLink href={"/services/development"}>
                            Разработка
                            {/* <DownArrowIcon
                          className={`${styles["dropdown-menu__trigger-icon"]}`}
                        /> */}
                          </NextLink>
                        </Link>
                      </AccordionTrigger>
                      {/* <AccordionContent className={styles["accordion-content"]}>
                        <ul
                          className={styles["nav__list-sub-content"]}
                          onMouseOver={(e) => e.stopPropagation()}
                        >
                          <li className={styles["dropdown-menu__item"]}>
                            <Link href="#">Корпоративные сайты</Link>
                          </li>
                          <li className={styles["dropdown-menu__item"]}>
                            <Link href="#">Личные кабинеты</Link>
                          </li>
                          <li className={styles["dropdown-menu__item"]}>
                            <Link href="#">eCommerce и маркетплейсы</Link>
                          </li>
                          <li className={styles["dropdown-menu__item"]}>
                            <Link href="#">
                              Решения для государства и корпораций
                            </Link>
                          </li>
                          <li className={styles["dropdown-menu__item"]}>
                            <Link href="#">Web-Приложения</Link>
                          </li>
                        </ul>
                      </AccordionContent> */}
                    </AccordionItem>
                    <AccordionItem
                      value={"item12"}
                      className={styles["accordion-item"]}
                      // data-active={currentStep === label}
                      key={"item12"}
                    >
                      <AccordionTrigger
                        className={styles["accordion-trigger"]}
                        asChild
                      >
                        <Link asChild>
                          <NextLink href={"/services/ux-ui"}>
                            UX/UI{" "}
                            {/* <DownArrowIcon
                          className={`${styles["dropdown-menu__trigger-icon"]}`}
                        /> */}
                          </NextLink>
                        </Link>
                      </AccordionTrigger>
                      {/* <AccordionContent className={styles["accordion-content"]}>
                        <ul
                          className={styles["nav__list-sub-content"]}
                          onMouseOver={(e) => e.stopPropagation()}
                        >
                          <li className={styles["dropdown-menu__item"]}>
                            <Link href="#">Корпоративные сайты</Link>
                          </li>
                          <li className={styles["dropdown-menu__item"]}>
                            <Link href="#">Личные кабинеты</Link>
                          </li>
                          <li className={styles["dropdown-menu__item"]}>
                            <Link href="#">Web-Приложения</Link>
                          </li>
                        </ul>
                      </AccordionContent> */}
                    </AccordionItem>
                    <AccordionItem
                      value={"item13"}
                      className={styles["accordion-item"]}
                      // data-active={currentStep === label}
                      disabled
                      key={"item13"}
                      asChild
                    >
                      <AccordionTrigger
                        className={styles["accordion-trigger"]}
                        asChild
                      >
                        <Link asChild>
                          <NextLink href="/services/integrations">
                            Интеграции
                          </NextLink>
                        </Link>
                      </AccordionTrigger>
                    </AccordionItem>
                    <AccordionItem
                      value={"item14"}
                      className={styles["accordion-item"]}
                      // data-active={currentStep === label}
                      disabled
                      key={"item14"}
                      asChild
                    >
                      <AccordionTrigger
                        className={styles["accordion-trigger"]}
                        asChild
                      >
                        <Link asChild>
                          <NextLink href="/services/promotion">
                            Продвижение
                          </NextLink>
                        </Link>
                      </AccordionTrigger>
                    </AccordionItem>
                    {/* <AccordionItem
                      value={"item15"}
                      className={styles["accordion-item"]}
                      // data-active={currentStep === label}
                      disabled
                      key={"item15"}
                      asChild
                    >
                      <AccordionTrigger
                        className={styles["accordion-trigger"]}
                        asChild
                      >
                        <Link href="#">Фирменный стиль</Link>
                      </AccordionTrigger>
                    </AccordionItem>
                    <AccordionItem
                      value={"item16"}
                      className={styles["accordion-item"]}
                      // data-active={currentStep === label}
                      disabled
                      key={"item16"}
                      asChild
                    >
                      <AccordionTrigger
                        className={styles["accordion-trigger"]}
                        asChild
                      >
                        <Link href="#">Аутстаффинг</Link>
                      </AccordionTrigger>
                    </AccordionItem>
                    <AccordionItem
                      value={"item17"}
                      className={styles["accordion-item"]}
                      // data-active={currentStep === label}
                      disabled
                      key={"item17"}
                      asChild
                    >
                      <AccordionTrigger
                        className={styles["accordion-trigger"]}
                        asChild
                      >
                        <Link href="#">Тех. сопровождение</Link>
                      </AccordionTrigger>
                    </AccordionItem>
                    <AccordionItem
                      value={"item18"}
                      className={styles["accordion-item"]}
                      // data-active={currentStep === label}
                      disabled
                      key={"item18"}
                      asChild
                    >
                      <AccordionTrigger
                        className={styles["accordion-trigger"]}
                        asChild
                      >
                        <Link href="#">Цифровизация бизнеса</Link>
                      </AccordionTrigger>
                    </AccordionItem> */}
                  </li>
                </Accordion>
              </ul>
            </li>
            {/* <li
            className={styles.nav__item}
            data-open={menuState["company"]?.open}
          >
            <Link
              href="#"
              onMouseOver={handleOpenMenu}
              onClick={handleOpenMenu}
              data-key="company"
              className={styles["nav__trigger"]}
            >
              Компания{" "}
              <DropDownIcon
                className={`${styles["nav__trigger-icon"]} ${styles["nav__trigger-icon-desktop"]}`}
                onMouseOver={(e) => e.stopPropagation()}
              />
              <RightArrowIcon
                onMouseOver={(e) => e.stopPropagation()}
                className={`${styles["dropdown-menu__trigger-icon"]} ${styles["dropdown-menu__trigger-icon-mobile"]}`}
              />
            </Link>
            <ul className={styles["nav__list-content"]}>
              <li className={styles["dropdown-menu__back"]}>
                <Link
                  onClick={() => {
                    setMenuState({});
                    setSubMenuState({});
                  }}
                  href="#"
                  className={styles["dropdown-menu__back-link"]}
                >
                  <LeftArrowIcon
                    className={`${styles["dropdown-menu__back-icon"]}`}
                  />
                  Назад
                </Link>
              </li>

              <li
                className={styles["dropdown-menu__item"]}
                onMouseOver={handleOpenSubMenu}
                onClick={handleOpenSubMenu}
              >
                <Link href="#">Продвижение</Link>
              </li>
              <li
                className={styles["dropdown-menu__item"]}
                onMouseOver={handleOpenSubMenu}
                onClick={handleOpenSubMenu}
              >
                <Link href="#">Фирменный стиль</Link>
              </li>
              <li
                className={styles["dropdown-menu__item"]}
                onMouseOver={handleOpenSubMenu}
                onClick={handleOpenSubMenu}
              >
                <Link href="#">Аутстаффинг</Link>
              </li>
              <li
                className={styles["dropdown-menu__item"]}
                onMouseOver={handleOpenSubMenu}
                onClick={handleOpenSubMenu}
              >
                <Link href="#">Тех. сопровождение</Link>
              </li>
              <li
                className={styles["dropdown-menu__item"]}
                onMouseOver={handleOpenSubMenu}
                onClick={handleOpenSubMenu}
              >
                <Link href="#">Цифровизация бизнеса</Link>
              </li>
            </ul>
          </li> */}
            <li className={styles.nav__item}>
              <Link onMouseOver={handleCloseMenu} asChild>
                <NextLink href="/cases">Портфолио</NextLink>
              </Link>
            </li>
            <li className={styles.nav__item}>
              <Link onMouseOver={handleCloseMenu} asChild>
                <NextLink href="/articles">Блог</NextLink>
              </Link>
            </li>
            <li className={styles.nav__item}>
              <Link onMouseOver={handleCloseMenu} asChild>
                <NextLink href="/contacts">Контакты</NextLink>
              </Link>
            </li>
          </ul>
        </nav>

        <Link href="tel:73952261260" className={styles.phone}>
          +7 (3952) 26-12-60
        </Link>
        <Button
          className={`${styles.button} ${styles.button__cta}`}
          variant="outline"
          size="sm"
          RightElement={UpRightArrowIcon}
          onClick={show}
        >
          Обсудить проект
        </Button>
        {/* <IconButton
        className={`${styles.icon__button} ${styles.user_icon}`}
        Icon={UserIcon}
        variant="square"
      /> */}
        <IconButton
          className={`${styles.icon__button} ${styles.menu__button}`}
          Icon={isMobileMenuOpen ? CloseIcon : BurgerMenuIcon}
          variant="square"
          onClick={handleOpenMobileMenu}
        />
      </header>
    </>
  );
};
Header.displayName = "Header";

export default Header;
