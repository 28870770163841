import React from "react";
import { Slot } from "@radix-ui/react-slot";

import styles from "./styles.module.css";
interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  asChild?: boolean;
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, className, asChild, ...rest }, ref) => {
    const Comp = asChild ? Slot : "a";
    return (
      <Comp className={`${styles.link} ${className || ""}`} {...rest}>
        {children}
      </Comp>
    );
  }
);
Link.displayName = "Link";
export default Link;
