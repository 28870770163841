"use client";

import React, { useMemo } from "react";
import NextLink from "next/link";
import Link from "@/components/Link";

import styles from "./styles.module.css";
import Button from "@/components/Button";
import IconButton from "@/components/IconButton";
import {
  BehanceIcon,
  DzenIcon,
  InstagramIcon,
  TelegramIcon,
  UpRightArrowIcon,
  VkIcon,
} from "@/components/Icon";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { socialLinks } from "@/const";
import { ContactDialog } from "../ContactDialog";
import { useModal } from "@/hooks/useModal";

const Footer: React.FC<React.HTMLProps<HTMLElement>> = ({
  className,
  ...rest
}) => {
  const pathname = usePathname();
  const logoAsLink = useMemo(() => pathname.split("/").length > 2, [pathname]);
  const { visible, show, close } = useModal({ defaultVisible: false });

  return (
    <>
      <ContactDialog open={visible} onClose={close} />
      <footer className={`${styles.footer} ${className || ""}`} {...rest}>
        <div className={styles.contact}>
          <Link href="tel:73952261260" className={styles.phone}>
            +7 (3952) 261-260
          </Link>
          <Button
            variant="primary"
            size="sm"
            RightElement={UpRightArrowIcon}
            className={styles.button}
            onClick={show}
          >
            Обсудить проект
          </Button>

          <ul className={`${styles.list} ${styles.list__social}`}>
            <li>
              <IconButton
                Icon={TelegramIcon}
                variant="circle"
                size="sm"
                asChild
              >
                <Link href={socialLinks.telegram} />
              </IconButton>
            </li>
            <li>
              <IconButton Icon={VkIcon} variant="circle" size="sm" asChild>
                <Link href={socialLinks.vk} />
              </IconButton>
            </li>
            <li>
              <IconButton Icon={BehanceIcon} variant="circle" size="sm" asChild>
                <Link href={socialLinks.behance} />
              </IconButton>
            </li>
            <li>
              <IconButton Icon={DzenIcon} variant="circle" size="sm" asChild>
                <Link href={socialLinks.dzen} />
              </IconButton>
            </li>
            <li>
              <IconButton
                Icon={InstagramIcon}
                variant="circle"
                size="sm"
                asChild
              >
                <Link href={socialLinks.instagram} />
              </IconButton>
            </li>
          </ul>
        </div>

        <nav className={styles.nav}>
          <div className={styles.services}>
            <Link className={styles.primary} asChild>
              <NextLink href="/services">Услуги</NextLink>
            </Link>

            <ul className={`${styles.list} ${styles.list__services}`}>
              <li>
                <Link href="#" className={styles.secondary} asChild>
                  <NextLink href="/services/development">Разработка</NextLink>
                </Link>
              </li>
              <li>
                <Link href="#" className={styles.secondary} asChild>
                  <NextLink href="/services/ux-ui">UX/UI</NextLink>
                </Link>
              </li>
              <li>
                <Link href="#" className={styles.secondary} asChild>
                  <NextLink href="/services/integrations">Интеграции</NextLink>
                </Link>
              </li>
              <li>
                <Link href="#" className={styles.secondary} asChild>
                  <NextLink href="/services/promotion">Продвижение</NextLink>
                </Link>
              </li>
            </ul>
          </div>

          <ul className={`${styles.list} ${styles.list__links}`}>
            <li className={styles.mobile}>
              <Link className={styles.primary} asChild>
                <NextLink href="/services">Услуги</NextLink>
              </Link>
            </li>
            {/* <li>
            <Link href="#" className={styles.primary}>
              Компания
            </Link>
          </li> */}
            <li>
              <Link className={styles.primary} asChild>
                <NextLink href="/cases">Портфолио</NextLink>
              </Link>
            </li>
            <li>
              <Link className={styles.primary} asChild>
                <NextLink href="/articles">Блог</NextLink>
              </Link>
            </li>
          </ul>

          <Link
            href="/privacy.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.confi} ${styles.secondary}`}
          >
            Политика конфиденциальности
          </Link>
        </nav>

        <div className={styles.rights}>
          <NextLink
            href="/"
            className={styles.logo__link}
            data-active={logoAsLink}
          >
            <Image
              src="/logo.svg"
              alt="TrueCode Logo"
              fill
              className={styles.logo}
              priority
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              />
          </NextLink>
          <span className={`${styles.secondary} ${styles.copyright}`}>
            © 2024, true.code
          </span>
        </div>

        <span className={`${styles.secondary} ${styles.copyright_mobile}`}>
          © 2024, true.code
        </span>
      </footer>
    </>
  );
};
Footer.displayName = "Footer";

export default Footer;
