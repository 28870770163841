"use client";

import React, { useCallback } from "react";
import { createPortal } from "react-dom";
import * as Dialog from "@radix-ui/react-dialog";
import Typography from "@/components/Typography";
import Button from "@/components/Button";
import styles from "./styles.module.css";
import { useModal } from "@/hooks/useModal";
import { setCookie, getCookie } from "cookies-next";

const COOKIE_KEY = "COOKIE_ACCCEPTED";
export const CookeiConsent: React.FC = () => {
  const { close, visible } = useModal({
    defaultVisible: getCookie(COOKIE_KEY) != "true",
  });

  const handleClose = useCallback(() => {
    setCookie(COOKIE_KEY, "true");
    close();
  }, [close]);

  return (
    <Dialog.Root open={visible}>
      <Dialog.Portal>
        <div
          className={`${styles.DialogContent} ${styles.DialogContentSuccess}`}
        >
          <Typography variant="p1" className={styles.message}>
            Используя данный сайт, вы даете согласие на использование файлов
            cookie, помогающих нам сделать его удобнее для вас.
          </Typography>

          <Button
            variant="tertiary"
            mode="light"
            className={styles.button}
            onClick={handleClose}
          >
            Принять и закрыть
          </Button>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
